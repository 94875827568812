<template>
  <div class="develop" v-if="!isProcessing">
    <div v-for="item in items" :key="item.title">
      <develop-menu :item="item" />
    </div>
  </div>
</template>

<script>
import DevelopMenu from '@/components/common/menu.vue'

export default {
  name: 'develop',
  components: { DevelopMenu },
  data () {
    return {
      items: [
        { title: 'コレクションリセット', content: '指定したコレクションのドキュメントを全削除する', to: 'develop_reset_collections' }
      ]
    }
  },
  mounted () {
    // 本番環境でURL直打ちなどで遷移してきた場合homeに遷移させる
    if (this.isProduction) {
      this.$store.commit('setTelop', { show: true, msg: '本番環境では利用できません', type: 'error' })
      this.$router.push({ name: 'home' })
    }
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 本番環境かどうか
     */
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.develop {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
